import { useRoute } from 'vue-router'

export function useQuery(offset=null) {
    const route = useRoute();
    
    const allQuery = () => {
        if(route && route.query) return JSON.parse(JSON.stringify(route.query))
    }
    const allParams = () => {
        if(route && route.params) return JSON.parse(JSON.stringify(route.params))
        
    }

    const getQueryString = () => {
        let queries = allQuery();
        let params = allParams();
        let query = '';
        if(params.companyId) query += `?company_id=${params.companyId}`
        if(queries.start) query += `&start_date=${queries.start}`
        if(queries.end) query += `&end_date=${queries.end}`
        if(queries.page) query += `&page=${queries.page}`
        if(offset) query += `&offset=${offset}`
        return query;
    }

    return {
        allQuery,
        allParams,
        getQueryString,
        params:  allParams()
    }
}