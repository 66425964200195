<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Costing Name</th>
          <th>Costing Number</th>
          <th>Customer</th>
          <th class="text-right">Bill of material cost</th>
          <th class="text-right">Total sale price</th>
          <th>Currency</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in productCosting" :key="i">
          <td> {{ item.date }}</td>
          <td> {{ item.product_costing_name }}</td>
          <td> {{ item.bill_number }}</td>
          <td> {{ item.customer_name }}</td>
          <td class="text-right"> {{ commaFormat(item.bom_cost) }}</td>
          <td class="text-right"> {{ commaFormat(item.total_sales_price) }}</td>
          <td> {{ item.currency }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item.id)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item.id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!productCosting.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter';
import {useRoute, useRouter} from "vue-router";
import handlePurchase from '@/services/modules/purchase';
import pdfPrinter from '@/services/utils/pdf/productCosting';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {formatDate} from '@/services/utils/global';

const {exportToPDF} = pdfPrinter();
const {fetchBarcode} = handleBarcode();
const {fetchSingleProductCosting} = handlePurchase()
const {fetchCompanyInfo} = handleCompany();
const {commaFormat} = figureFormatter()
const props = defineProps({
  productCosting: Array
})
const companyId = computed(() => route.params.companyId)
const route = useRoute()
const router = useRouter()
const store = useStore()
const companyInfo = ref({})
const singleProductCosting = ref({})
const barcodeQuery = ref('')
const barcode = ref('')
const qrcode = ref('')
const goToEdit = (id) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
  }
  param['productCostingId'] = id;
  router.push({
    name: 'product-costing-edit',
    params: param,
    query: route.query
  })
}
const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;

  fetchSingleProductCosting(id, companyQuery).then(({status, data}) => {
    if (status) {
      singleProductCosting.value = data;
      let qrcodeData = JSON.stringify({
        ref_no: singleProductCosting.value.bill_number,
        ref_date: singleProductCosting.value.date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery.value = `?code=${singleProductCosting.value.bill_number}&qrcode=${qrcodeData}`;
    }
  }).then(() => {
    fetchBarcode(barcodeQuery.value).then((res) => {
      barcode.value = res.barcode;
      qrcode.value = res.qrcode
    }).then(() => {
      exportToPDF(companyInfo.value, singleProductCosting.value, barcode.value, qrcode.value, user.name)
    })
  }).catch((error) => {
    console.log(error);
  })
}

onMounted(() => {
  fetchCompanyInfo(companyId.value).then((res) => {
    companyInfo.value = res.data;
  }).catch((error) => {
    console.log(error);
  });
})
</script>
