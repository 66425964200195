import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
import handlePurchase from "@/services/modules/purchase";
import figureFormatter from "@/services/utils/figureFormatter";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const productCostingPDF = () => {
    const {getHeader, getFooter} = pdfHeaderFooter();
    const {currencyToWord} = handlePurchase();
    const { commaFormat } = figureFormatter();
    const exportToPDF = (company, productCosting, barcode, qrcode, userName) => {

        const doc = {
            pageSize: 'A4',
            pageMargins: [25, 100, 25, 80],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(productCosting, barcode),

            styles: {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Product Costing Report'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (productCosting, barcode) => {
        return [
            {
                text: 'Product Costing',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        stack: [
                            {text: `Product Name: ${productCosting.product.text} (${productCosting.product.description.unit.name})`},
                            {text: `${productCosting.contact_profile.name ? 'Customer Name: ' + productCosting.contact_profile.name : ''}`},
                            {text: `${productCosting.contact_buyer ? 'Buyer Name: ' + productCosting.contact_buyer.name : ''}`},
                            {text: `Costing Name: ${productCosting.product_costing_name}`}
                        ]
                    },
                    {
                        stack: [
                            {text: `Costing Qty: ${productCosting.costing_qty}`},
                            {text: `Currency: ${productCosting.currency}`},
                            {text: `BoM Cost: ${commaFormat(productCosting.bom_cost)}`}
                        ]
                    },
                    {
                        alignment: 'right',
                        stack: [
                            {svg: barcode},
                            {text: `${'Date: ' + productCosting.date}`},
                            {text: `${'Bill Number: ' + productCosting.bill_number}`},
                        ]
                    },
                ]
            },
            ...generateGroupedTables(productCosting.general),
            ...generateSummaryTables(productCosting),

            {
                margin: [0, 15],
                text: [
                    {text: 'In Words: ', bold: true},
                    {text: `${productCosting.total_sales_price ? currencyToWord(productCosting.total_sales_price) : ''}`}
                ]
            },
            {
                stack: [
                    {text: `${productCosting.description ? 'Note: ' : ''}`, bold: true},
                    {text: `${productCosting.description ? productCosting.description : ''}`}
                ]
            }
        ];
    };

    const createTable = (body, widths = ['*', '*'], fillRowIndex = 0, margin = [0, 10, 0, 0]) => ({
        style: 'tableExample',
        margin: margin,
        table: {
            widths: widths,
            body: body
        },
        layout: {
            fillColor: (rowIndex) => (rowIndex === fillRowIndex ? '#f3f2f7' : null),
            hLineWidth: () => 0.5,
            vLineWidth: () => 0.5,
        }
    });

    const calculateTotal = (items, key = 'amount') => items.reduce((total, item) => total + parseFloat(item[key] || 0), 0).toFixed(4);

    const generateGroupedTables = (general) => {
        const groupedByType = groupByType(general);
        const tableContent = [];
        let primeCost = 0;

        const typesInOrder = ['raw_materials', 'packing_materials', 'direct_labor', 'direct_overhead'];

        typesInOrder.forEach(type => {
            tableContent.push({
                text: getTypeName(type),
                style: 'tableHeading',
                margin: [0, 10, 0, 0],
                bold: true
            });

            const items = groupedByType[type] || [];

            if (['raw_materials', 'packing_materials', 'direct_labor'].includes(type)) {
                primeCost += parseFloat(calculateTotal(items));
            }

            tableContent.push(createTable(
                items.length > 0 ? getTableBody(items) : [[{ text: 'No data available', colSpan: getTableWidths(type).length, alignment: 'center' }]],
                getTableWidths(type),
                0,
                [0, 0, 0, 0]
            ));

            if (type === 'direct_labor') {
                tableContent.push(createTable([
                    [{ text: 'Prime Cost (A+B+C)', alignment: 'center', bold: true }, { text: commaFormat(primeCost), alignment: 'right', bold: true }]
                ]));
            }
        });

        const totalManufacturingCost = calculateTotal(general);

        tableContent.push(createTable([
            [{ text: 'Total manufacturing cost (A+B+C+D)', alignment: 'center', bold: true }, { text: commaFormat(totalManufacturingCost), alignment: 'right', bold: true }]
        ]));

        return tableContent;
    };

    const generateSummaryTables = (productCosting) => {
        const tableContent = [];
        const totalManufacturingCost = calculateTotal(productCosting.general);
        const totalCost = (parseFloat(totalManufacturingCost) + parseFloat(productCosting.general_admin_overheads)).toFixed(4);

        tableContent.push(createTable([
            [{ text: 'E. General & Admin Overheads', alignment: 'center', bold: true }, { text: commaFormat(productCosting.general_admin_overheads), alignment: 'right', bold: true }]
        ]));

        tableContent.push(createTable([
            [{ text: 'Total Cost (A+B+C+D+E)', alignment: 'center', bold: true }, { text: commaFormat(totalCost), alignment: 'right', bold: true }]
        ]));

        const markupAmount = ((totalCost * productCosting.markup) / 100).toFixed(4);
        const salePriceWithoutVat = (parseFloat(markupAmount) + parseFloat(totalCost)).toFixed(4);
        const vatAmount = ((salePriceWithoutVat * productCosting.vat) / 100).toFixed(4);
        const salesPriceIncludingVat = (parseFloat(salePriceWithoutVat) + parseFloat(vatAmount)).toFixed(4);

        tableContent.push(createTable([
            [{ text: 'Markup %' }, { text: productCosting.markup, alignment: 'center' }, { text: commaFormat(markupAmount), alignment: 'right', bold: true }],
            [{ text: 'Sales price without VAT' }, {}, { text: commaFormat(salePriceWithoutVat), alignment: 'right', bold: true }],
            [{ text: 'VAT' }, { text: productCosting.vat, alignment: 'center' }, { text: commaFormat(vatAmount), alignment: 'right', bold: true }],
            [{ text: 'Sales price with VAT' }, {}, { text: commaFormat(salesPriceIncludingVat), alignment: 'right', bold: true }]
        ], ['*', '*', '*']));

        return tableContent;
    };

    const getTypeName = (type) => {
        switch (type) {
            case 'raw_materials':
                return 'A. Raw Materials';
            case 'packing_materials':
                return 'B. Packing Materials';
            case 'direct_labor':
                return 'C. Direct Labor';
            case 'direct_overhead':
                return 'D. Direct Overhead';
            default:
                return 'Other Items';
        }
    };

    const groupByType = (items) => {
        return items.reduce((acc, item) => {
            if (!acc[item.type]) {
                acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
        }, {});
    };

    const getTableWidths = (type) => {
        if (type === 'raw_materials' || type === 'packing_materials') {
            return ['*', '*', '*', '*', '*', '*', '*', '*'];
        }
        return ['*', '*', '*', '*'];
    };

    const getTableBody = (tableItems) => {
        const tableData = [];

        const header = getTableHead(tableItems[0].type);
        tableData.push(header);

        tableItems.forEach(item => {
            const row = getTableRow(item);
            tableData.push(row);
        });

        const totalAmount = calculateTotalAmount(tableItems);

        const footer = getTableFooter(totalAmount, tableItems[0].type);
        tableData.push(footer);

        return tableData;
    };

    const calculateTotalAmount = (tableItems) => {
        return tableItems.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
    };

    const getTableFooter = (totalAmount, type) => {
        if (type === 'raw_materials' || type === 'packing_materials') {
            return [
                {text: 'Total', colSpan: 7, alignment: 'center', bold: true}, {}, {}, {}, {}, {}, {},
                {text: commaFormat(totalAmount), alignment: 'right', bold: true}
            ];
        }

        return [
            {text: 'Total', colSpan: 3, alignment: 'center', bold: true}, {}, {},
            {text: commaFormat(totalAmount), alignment: 'right', bold: true}
        ];
    };

    const getTableHead = (type) => {
        if (type === 'raw_materials' || type === 'packing_materials') {
            return [
                {text: 'Item Description'},
                {text: 'UoM', alignment: 'center'},
                {text: 'Qty', alignment: 'center'},
                {text: 'Wastage %', alignment: 'center'},
                {text: 'Wastage Qty', alignment: 'center'},
                {text: 'Total Qty', alignment: 'center'},
                {text: 'Rate', alignment: 'center'},
                {text: 'Amount', alignment: 'right'}
            ];
        }

        return [
            {text: 'Item Description'},
            {text: 'Qty', alignment: 'center'},
            {text: 'Rate', alignment: 'center'},
            {text: 'Amount', alignment: 'right'}
        ];
    };

    const getTableRow = (item) => {
        if (item.type === 'raw_materials' || item.type === 'packing_materials') {
            return [
                {text: item.product_costingable.text},
                {text: item.product_costingable.description.unit.name, alignment: 'center'},
                {text: item.quantity, alignment: 'center'},
                {text: item.wastage, alignment: 'center'},
                {text: item.wastage_quantity, alignment: 'center'},
                {text: item.total_quantity, alignment: 'center'},
                {text: item.rate, alignment: 'center'},
                {text: commaFormat(item.amount), alignment: 'right'}
            ];
        }

        return [
            {text: item.product_costingable.name},
            {text: item.total_quantity, alignment: 'center'},
            {text: item.rate, alignment: 'center'},
            {text: commaFormat(item.amount), alignment: 'right'}
        ];
    };

    return {
        exportToPDF
    }
}

export default productCostingPDF;
