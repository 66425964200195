<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Product Costing list"
          @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getProductCostingList"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label" for="product_id">Product/Item</label>
          <AsyncSelect
              placeholder="Select Product"
              v-model="product"
              :api-service="fetchProductList"
              :format-label="option => option.text"
              label="text"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Customer: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
          </div>

          <AsyncSelect
              placeholder="Select Customer"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Costing Status</label>
          <v-select
              placeholder="Select Costing Status"
              v-model="selectedCostingStatus"
              :options="costingStatuses"
              label="name"
              :reduce="option => option.value"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Costing Name/Number: </label>
          </div>

          <input
              placeholder="Costing Name/Number"
              v-model="searchTerm"
              class="form-control"
          />
        </div>
      </DateQuerySetter>

    </div>
    <div class="col-12 px-2 mt-1">
      <ListTable :product-costing="productCostingList"/>
    </div>
    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="customer"
    />
    <GlobalLoader/>
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {ref, onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/product-costing/ListTable.vue'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleContact from "@/services/modules/contact";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {trim} from "lodash";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let productCostingList = ref([])
const route = useRoute()
let product = ref(null)
let contactProfile = ref(null)
let searchTerm = ref(null)
let selectedCostingStatus = ref(null)
const costingStatuses = ref([]);

const {fetchProductCostingList, fetchProductList, fetchOrderStatus} = handlePurchase()
const {fetchContactProfiles} = handleContact();

const companyId = computed(() => route.params.companyId)
const startDate = computed(() => route.query.start)
const endDate = computed(() => route.query.end)
const page = computed(() => route.query.page)

const additionalContactOption = ref({
  roles: 'customer'
});

function navigateToAddPage() {
  router.push({name: 'product-costing-add', params: route.params, query: route.query})
}

const getOrderStatus = async () => {
  const res = await fetchOrderStatus('?company_id=' + companyId.value);
  if (res.status) {
    costingStatuses.value = res.data.filter(item => ['active', 'inactive'].includes(item.value))
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (contactProfile.value) query += '&contact_profile_id=' + contactProfile.value.id
  if (product.value) query += '&product_id=' + product.value.id
  if (searchTerm.value && trim(searchTerm.value) !== '') query += '&search_term=' + trim(searchTerm.value)
  if (selectedCostingStatus.value) query += '&status=' + selectedCostingStatus.value
  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset.value
  return query
}

function getProductCostingList() {
  fetchProductCostingList(getQuery()).then(({status, data}) => {
    if (!status) {
      productCostingList.value = []
      return store.commit("resetPagination")
    }
    productCostingList.value = data.data
    setPagination(data)
  })
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getProductCostingList()
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

onMounted(() => {
  getProductCostingList()
  getOrderStatus()
})

</script>
